// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root {\n  --background-color: #ffffff;\n  --text-color: #000000;\n  --border-color: rgba(0, 0, 0, 0.1);\n  --shadow-color: rgba(0, 0, 0, 0.2); }\n\n.styles_container__MNIXz {\n  display: flex;\n  align-items: center;\n  flex-wrap: wrap; }\n  .styles_container__MNIXz .styles_tag__3C_88 {\n    display: inline-flex;\n    background-color: var(--border-color);\n    color: var(--text-color);\n    border-radius: 4px;\n    padding: 4px 8px;\n    margin-right: 6px;\n    margin-bottom: 6px;\n    text-transform: uppercase;\n    font-size: 14px;\n    transition: background-color 0.5s ease-out, color 0.5s ease-out; }\n", "",{"version":3,"sources":["webpack://src/styles/variables.scss","webpack://src/components/tags/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,2BAAmB;EACnB,qBAAa;EACb,kCAAe;EACf,kCAAe,EAAA;;ACFjB;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe,EAAA;EAHjB;IAMI,oBAAoB;IACpB,qCAAqC;IACrC,wBAAwB;IACxB,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;IAClB,yBAAyB;IACzB,eAAe;IACf,+DACmC,EAAA","sourcesContent":[":root {\n  --background-color: #ffffff;\n  --text-color: #000000;\n  --border-color: rgba(0, 0, 0, 0.1);\n  --shadow-color: rgba(0, 0, 0, 0.2);\n}\n\n$theme-duration: 0.5s;\n$mobile-padding: 1.25rem;\n","@import '../../styles/variables.scss';\n\n.container {\n  display: flex;\n  align-items: center;\n  flex-wrap: wrap;\n\n  .tag {\n    display: inline-flex;\n    background-color: var(--border-color);\n    color: var(--text-color);\n    border-radius: 4px;\n    padding: 4px 8px;\n    margin-right: 6px;\n    margin-bottom: 6px;\n    text-transform: uppercase;\n    font-size: 14px;\n    transition: background-color #{$theme-duration} ease-out,\n      color #{$theme-duration} ease-out;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__MNIXz",
	"tag": "styles_tag__3C_88"
};
export default ___CSS_LOADER_EXPORT___;
