// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root {\n  --background-color: #ffffff;\n  --text-color: #000000;\n  --border-color: rgba(0, 0, 0, 0.1);\n  --shadow-color: rgba(0, 0, 0, 0.2); }\n\n.styles_container__1DKyt {\n  width: 100%; }\n  .styles_container__1DKyt .styles_square__3HCRn {\n    display: block;\n    width: 100%;\n    margin: 0 auto;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    text-align: center;\n    color: var(--background-color);\n    background-color: var(--text-color);\n    transition: background-color 0.5s ease-out, color 0.5s ease-out; }\n    .styles_container__1DKyt .styles_square__3HCRn:after {\n      content: '';\n      display: block;\n      padding-bottom: 100%; }\n", "",{"version":3,"sources":["webpack://src/styles/variables.scss","webpack://src/components/media-types/unknown/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,2BAAmB;EACnB,qBAAa;EACb,kCAAe;EACf,kCAAe,EAAA;;ACDjB;EACE,WAAW,EAAA;EADb;IAII,cAAc;IACd,WAAW;IACX,cAAc;IACd,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,8BAA8B;IAC9B,mCAAmC;IACnC,+DACmC,EAAA;IAdvC;MAiBM,WAAW;MACX,cAAc;MACd,oBAAoB,EAAA","sourcesContent":[":root {\n  --background-color: #ffffff;\n  --text-color: #000000;\n  --border-color: rgba(0, 0, 0, 0.1);\n  --shadow-color: rgba(0, 0, 0, 0.2);\n}\n\n$theme-duration: 0.5s;\n$mobile-padding: 1.25rem;\n","@import '../../../styles/layout.scss';\n@import '../../../styles/variables.scss';\n\n.container {\n  width: 100%;\n\n  .square {\n    display: block;\n    width: 100%;\n    margin: 0 auto;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    text-align: center;\n    color: var(--background-color);\n    background-color: var(--text-color);\n    transition: background-color #{$theme-duration} ease-out,\n      color #{$theme-duration} ease-out;\n\n    &:after {\n      content: '';\n      display: block;\n      padding-bottom: 100%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__1DKyt",
	"square": "styles_square__3HCRn"
};
export default ___CSS_LOADER_EXPORT___;
