// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__16Osc {\n  position: relative;\n  display: block;\n  width: 100%;\n  margin: 0 auto;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  pointer-events: none; }\n  .styles_container__16Osc:after {\n    content: '';\n    display: block;\n    padding-bottom: 100%; }\n  .styles_container__16Osc.styles_interactive__3dnh5 {\n    pointer-events: all; }\n  .styles_container__16Osc iframe {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    width: 100%;\n    height: 100%;\n    transform: translateX(-50%) translateY(-50%);\n    border: none; }\n", "",{"version":3,"sources":["webpack://src/components/media-types/vector/styles.module.scss"],"names":[],"mappings":"AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,WAAW;EACX,cAAc;EACd,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,oBAAoB,EAAA;EARtB;IAWI,WAAW;IACX,cAAc;IACd,oBAAoB,EAAA;EAbxB;IAiBI,mBAAmB,EAAA;EAjBvB;IAqBI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,WAAW;IACX,YAAY;IACZ,4CAA4C;IAC5C,YAAY,EAAA","sourcesContent":["@import '../../../styles/layout.scss';\n\n.container {\n  position: relative;\n  display: block;\n  width: 100%;\n  margin: 0 auto;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  pointer-events: none;\n\n  &:after {\n    content: '';\n    display: block;\n    padding-bottom: 100%;\n  }\n\n  &.interactive {\n    pointer-events: all;\n  }\n\n  iframe {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    width: 100%;\n    height: 100%;\n    transform: translateX(-50%) translateY(-50%);\n    border: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__16Osc",
	"interactive": "styles_interactive__3dnh5"
};
export default ___CSS_LOADER_EXPORT___;
