// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__wNbun {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center; }\n  .styles_container__wNbun .styles_video__32uJf {\n    display: block;\n    width: 100%;\n    height: auto;\n    margin: 0 auto; }\n    @media (min-width: 600px) {\n      .styles_container__wNbun .styles_video__32uJf {\n        max-height: 60vh;\n        width: auto;\n        height: initial;\n        max-width: 100%; } }\n", "",{"version":3,"sources":["webpack://src/components/media-types/video/styles.module.scss","webpack://src/styles/layout.scss"],"names":[],"mappings":"AAEA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB,EAAA;EALzB;IAQI,cAAc;IACd,WAAW;IACX,YAAY;IACZ,cAAc,EAAA;ICDd;MDVJ;QAcM,gBAAgB;QAChB,WAAW;QACX,eAAe;QACf,eAAe,EAAA,EAElB","sourcesContent":["@import '../../../styles/layout.scss';\n\n.container {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  .video {\n    display: block;\n    width: 100%;\n    height: auto;\n    margin: 0 auto;\n\n    @include respond-to('tablet') {\n      max-height: 60vh;\n      width: auto;\n      height: initial;\n      max-width: 100%;\n    }\n  }\n}\n","// breakpoints\n$TABLET: 600px;\n$DESKTOP: 1024px;\n\n$breakpoints: (\n  'desktop': $DESKTOP,\n  'tablet': $TABLET,\n  'phone': 0px,\n) !default;\n\n@mixin respond-to($breakpoint) {\n  @if map-has-key($breakpoints, $breakpoint) {\n    @media (min-width: map-get($breakpoints, $breakpoint)) {\n      @content;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__wNbun",
	"video": "styles_video__32uJf"
};
export default ___CSS_LOADER_EXPORT___;
