// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root {\n  --background-color: #ffffff;\n  --text-color: #000000;\n  --border-color: rgba(0, 0, 0, 0.1);\n  --shadow-color: rgba(0, 0, 0, 0.2); }\n\n.styles_container__1Yt5m {\n  width: 100%;\n  margin: 0 auto;\n  margin-bottom: 20px;\n  word-break: break-word; }\n  @media (min-width: 1024px) {\n    .styles_container__1Yt5m {\n      width: 50%; } }\n  @media (min-width: 1024px) {\n    .styles_container__1Yt5m.styles_large__3HUg_ {\n      width: 55%; } }\n  @media (min-width: 1024px) {\n    .styles_container__1Yt5m.styles_xlarge__1dVRr {\n      width: 80%; } }\n", "",{"version":3,"sources":["webpack://src/styles/variables.scss","webpack://src/components/layout/container/styles.module.scss","webpack://src/styles/layout.scss"],"names":[],"mappings":"AAAA;EACE,2BAAmB;EACnB,qBAAa;EACb,kCAAe;EACf,kCAAe,EAAA;;ACDjB;EACE,WAAW;EACX,cAAc;EACd,mBAAmB;EACnB,sBAAsB,EAAA;ECKpB;IDTJ;MAOI,UAAU,EAAA,EAcb;ECZG;IDTJ;MAYM,UAAU,EAAA,EAEb;ECLC;IDTJ;MAkBM,UAAU,EAAA,EAEb","sourcesContent":[":root {\n  --background-color: #ffffff;\n  --text-color: #000000;\n  --border-color: rgba(0, 0, 0, 0.1);\n  --shadow-color: rgba(0, 0, 0, 0.2);\n}\n\n$theme-duration: 0.5s;\n$mobile-padding: 1.25rem;\n","@import '../../../styles/variables.scss';\n@import '../../../styles/layout.scss';\n\n.container {\n  width: 100%;\n  margin: 0 auto;\n  margin-bottom: 20px;\n  word-break: break-word;\n\n  @include respond-to('desktop') {\n    width: 50%;\n  }\n\n  &.large {\n    @include respond-to('desktop') {\n      width: 55%;\n    }\n  }\n\n  &.xlarge {\n    @include respond-to('desktop') {\n      width: 80%;\n    }\n  }\n}\n","// breakpoints\n$TABLET: 600px;\n$DESKTOP: 1024px;\n\n$breakpoints: (\n  'desktop': $DESKTOP,\n  'tablet': $TABLET,\n  'phone': 0px,\n) !default;\n\n@mixin respond-to($breakpoint) {\n  @if map-has-key($breakpoints, $breakpoint) {\n    @media (min-width: map-get($breakpoints, $breakpoint)) {\n      @content;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__1Yt5m",
	"large": "styles_large__3HUg_",
	"xlarge": "styles_xlarge__1dVRr"
};
export default ___CSS_LOADER_EXPORT___;
