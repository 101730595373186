// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root {\n  --background-color: #ffffff;\n  --text-color: #000000;\n  --border-color: rgba(0, 0, 0, 0.1);\n  --shadow-color: rgba(0, 0, 0, 0.2); }\n\n.styles_container__qTtAh {\n  padding: 6px 0;\n  border-bottom: 1px solid var(--border-color);\n  transition: border-color 0.5s ease-out; }\n  .styles_container__qTtAh > * {\n    margin-right: 1em; }\n", "",{"version":3,"sources":["webpack://src/styles/variables.scss","webpack://src/components/menu/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,2BAAmB;EACnB,qBAAa;EACb,kCAAe;EACf,kCAAe,EAAA;;ACFjB;EACE,cAAc;EACd,4CAA4C;EAC5C,sCAAoD,EAAA;EAHtD;IAMI,iBAAiB,EAAA","sourcesContent":[":root {\n  --background-color: #ffffff;\n  --text-color: #000000;\n  --border-color: rgba(0, 0, 0, 0.1);\n  --shadow-color: rgba(0, 0, 0, 0.2);\n}\n\n$theme-duration: 0.5s;\n$mobile-padding: 1.25rem;\n","@import '../../styles/variables.scss';\n\n.container {\n  padding: 6px 0;\n  border-bottom: 1px solid var(--border-color);\n  transition: border-color #{$theme-duration} ease-out;\n\n  & > * {\n    margin-right: 1em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__qTtAh"
};
export default ___CSS_LOADER_EXPORT___;
