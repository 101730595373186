// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__2hTuV {\n  margin-bottom: 10px; }\n  .styles_container__2hTuV label {\n    display: block;\n    width: 100%;\n    padding: 6px;\n    border: 2px dashed var(--text-color);\n    text-align: center;\n    cursor: pointer; }\n    .styles_container__2hTuV label input {\n      display: none;\n      width: 100%; }\n  .styles_container__2hTuV .styles_allowed__3eXuT {\n    display: inline-flex;\n    font-size: 12px;\n    padding-top: 6px;\n    text-transform: lowercase;\n    opacity: 0.5; }\n    .styles_container__2hTuV .styles_allowed__3eXuT > *.styles_selected__2EdAr {\n      font-weight: bold; }\n", "",{"version":3,"sources":["webpack://src/components/upload/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAmB,EAAA;EADrB;IAII,cAAc;IACd,WAAW;IACX,YAAY;IACZ,oCAAoC;IACpC,kBAAkB;IAClB,eAAe,EAAA;IATnB;MAYM,aAAa;MACb,WAAW,EAAA;EAbjB;IAkBI,oBAAoB;IACpB,eAAe;IACf,gBAAgB;IAChB,yBAAyB;IACzB,YAAY,EAAA;IAtBhB;MA0BQ,iBAAiB,EAAA","sourcesContent":[".container {\n  margin-bottom: 10px;\n\n  label {\n    display: block;\n    width: 100%;\n    padding: 6px;\n    border: 2px dashed var(--text-color);\n    text-align: center;\n    cursor: pointer;\n\n    input {\n      display: none;\n      width: 100%;\n    }\n  }\n\n  .allowed {\n    display: inline-flex;\n    font-size: 12px;\n    padding-top: 6px;\n    text-transform: lowercase;\n    opacity: 0.5;\n\n    & > * {\n      &.selected {\n        font-weight: bold;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__2hTuV",
	"allowed": "styles_allowed__3eXuT",
	"selected": "styles_selected__2EdAr"
};
export default ___CSS_LOADER_EXPORT___;
