// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root {\n  --background-color: #ffffff;\n  --text-color: #000000;\n  --border-color: rgba(0, 0, 0, 0.1);\n  --shadow-color: rgba(0, 0, 0, 0.2); }\n\n.styles_identicon__1F5nd svg {\n  border-radius: 100%;\n  padding: 1px;\n  width: 120px;\n  height: 120px;\n  border: 2px solid var(--text-color);\n  transition: border-color 0.5s ease-out; }\n  .styles_identicon__1F5nd svg path {\n    stroke: var(--text-color);\n    fill: var(--text-color);\n    transition: all 0.5s ease-out; }\n\n.styles_identicon__1F5nd img {\n  border-radius: 100%;\n  padding: 1px;\n  width: 120px;\n  height: 120px;\n  border: 2px solid var(--text-color);\n  transition: border-color 0.5s ease-out; }\n", "",{"version":3,"sources":["webpack://src/styles/variables.scss","webpack://src/components/identicons/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,2BAAmB;EACnB,qBAAa;EACb,kCAAe;EACf,kCAAe,EAAA;;ACFjB;EAEI,mBAAmB;EACnB,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,mCAAmC;EACnC,sCAAoD,EAAA;EAPxD;IAUM,yBAAyB;IACzB,uBAAuB;IACvB,6BAA2C,EAAA;;AAZjD;EAiBI,mBAAmB;EACnB,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,mCAAmC;EACnC,sCAAoD,EAAA","sourcesContent":[":root {\n  --background-color: #ffffff;\n  --text-color: #000000;\n  --border-color: rgba(0, 0, 0, 0.1);\n  --shadow-color: rgba(0, 0, 0, 0.2);\n}\n\n$theme-duration: 0.5s;\n$mobile-padding: 1.25rem;\n","@import '../../styles/variables.scss';\n\n.identicon {\n  svg {\n    border-radius: 100%;\n    padding: 1px;\n    width: 120px;\n    height: 120px;\n    border: 2px solid var(--text-color);\n    transition: border-color #{$theme-duration} ease-out;\n\n    path {\n      stroke: var(--text-color);\n      fill: var(--text-color);\n      transition: all #{$theme-duration} ease-out;\n    }\n  }\n\n  img {\n    border-radius: 100%;\n    padding: 1px;\n    width: 120px;\n    height: 120px;\n    border: 2px solid var(--text-color);\n    transition: border-color #{$theme-duration} ease-out;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"identicon": "styles_identicon__1F5nd"
};
export default ___CSS_LOADER_EXPORT___;
