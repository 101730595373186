// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__3snt0 svg path {\n  fill: var(--text-color); }\n\n.styles_container__3snt0 svg:hover {\n  opacity: 0.7; }\n", "",{"version":3,"sources":["webpack://src/components/artist-socials/styles.module.scss"],"names":[],"mappings":"AAAA;EAGM,uBAAuB,EAAA;;AAH7B;EAOM,YAAY,EAAA","sourcesContent":[".container {\n  svg {\n    path {\n      fill: var(--text-color);\n    }\n\n    &:hover {\n      opacity: 0.7;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__3snt0"
};
export default ___CSS_LOADER_EXPORT___;
