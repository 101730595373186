// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root {\n  --background-color: #ffffff;\n  --text-color: #000000;\n  --border-color: rgba(0, 0, 0, 0.1);\n  --shadow-color: rgba(0, 0, 0, 0.2); }\n\n.styles_container__GZzHE {\n  color: var(--background-color);\n  transition: color 0.5s ease-out;\n  background-color: transparent;\n  border: none; }\n  .styles_container__GZzHE:focus {\n    outline: none; }\n", "",{"version":3,"sources":["webpack://src/styles/variables.scss","webpack://src/components/button-language/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,2BAAmB;EACnB,qBAAa;EACb,kCAAe;EACf,kCAAe,EAAA;;ACFjB;EACE,8BAA8B;EAC9B,+BAA6C;EAC7C,6BAA6B;EAC7B,YAAY,EAAA;EAJd;IAOI,aAAa,EAAA","sourcesContent":[":root {\n  --background-color: #ffffff;\n  --text-color: #000000;\n  --border-color: rgba(0, 0, 0, 0.1);\n  --shadow-color: rgba(0, 0, 0, 0.2);\n}\n\n$theme-duration: 0.5s;\n$mobile-padding: 1.25rem;\n","@import '../../styles/variables.scss';\n\n.container {\n  color: var(--background-color);\n  transition: color #{$theme-duration} ease-out;\n  background-color: transparent;\n  border: none;\n\n  &:focus {\n    outline: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__GZzHE"
};
export default ___CSS_LOADER_EXPORT___;
