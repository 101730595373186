// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root {\n  --background-color: #ffffff;\n  --text-color: #000000;\n  --border-color: rgba(0, 0, 0, 0.1);\n  --shadow-color: rgba(0, 0, 0, 0.2); }\n\n.styles_container__qxBg_ {\n  display: flex;\n  width: 16px;\n  height: 16px;\n  border-radius: 50%;\n  background-color: var(--background-color);\n  transition: background-color 0.5s ease-out, color 0.5s ease-out;\n  z-index: 1000;\n  cursor: pointer; }\n", "",{"version":3,"sources":["webpack://src/styles/variables.scss","webpack://src/components/button-theme/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,2BAAmB;EACnB,qBAAa;EACb,kCAAe;EACf,kCAAe,EAAA;;ACFjB;EACE,aAAa;EACb,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,yCAAyC;EACzC,+DACmC;EACnC,aAAa;EACb,eAAe,EAAA","sourcesContent":[":root {\n  --background-color: #ffffff;\n  --text-color: #000000;\n  --border-color: rgba(0, 0, 0, 0.1);\n  --shadow-color: rgba(0, 0, 0, 0.2);\n}\n\n$theme-duration: 0.5s;\n$mobile-padding: 1.25rem;\n","@import '../../styles/variables.scss';\n\n.container {\n  display: flex;\n  width: 16px;\n  height: 16px;\n  border-radius: 50%;\n  background-color: var(--background-color);\n  transition: background-color #{$theme-duration} ease-out,\n    color #{$theme-duration} ease-out;\n  z-index: 1000;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__qxBg_"
};
export default ___CSS_LOADER_EXPORT___;
