// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root {\n  --background-color: #ffffff;\n  --text-color: #000000;\n  --border-color: rgba(0, 0, 0, 0.1);\n  --shadow-color: rgba(0, 0, 0, 0.2); }\n\n.styles_menu__1oMTN {\n  margin-left: 6px; }\n  .styles_menu__1oMTN svg {\n    display: block;\n    width: 30px;\n    height: 30px; }\n    .styles_menu__1oMTN svg line {\n      stroke: var(--text-color);\n      transition: stroke 0.5s ease-out; }\n", "",{"version":3,"sources":["webpack://src/styles/variables.scss","webpack://src/components/icons/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,2BAAmB;EACnB,qBAAa;EACb,kCAAe;EACf,kCAAe,EAAA;;ACFjB;EACE,gBAAgB,EAAA;EADlB;IAKI,cAAc;IACd,WAAW;IACX,YAAY,EAAA;IAPhB;MAUM,yBAAyB;MACzB,gCAA8C,EAAA","sourcesContent":[":root {\n  --background-color: #ffffff;\n  --text-color: #000000;\n  --border-color: rgba(0, 0, 0, 0.1);\n  --shadow-color: rgba(0, 0, 0, 0.2);\n}\n\n$theme-duration: 0.5s;\n$mobile-padding: 1.25rem;\n","@import '../../styles/variables.scss';\n\n.menu {\n  margin-left: 6px;\n  // border: 1px dashed black;\n\n  svg {\n    display: block;\n    width: 30px;\n    height: 30px;\n\n    line {\n      stroke: var(--text-color);\n      transition: stroke #{$theme-duration} ease-out;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu": "styles_menu__1oMTN"
};
export default ___CSS_LOADER_EXPORT___;
