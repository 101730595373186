// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root {\n  --background-color: #ffffff;\n  --text-color: #000000;\n  --border-color: rgba(0, 0, 0, 0.1);\n  --shadow-color: rgba(0, 0, 0, 0.2); }\n\n.styles_container__2ADv- {\n  position: relative; }\n  .styles_container__2ADv- .styles_number__3LWL0 {\n    position: absolute;\n    bottom: 6px;\n    right: 6px;\n    padding: 4px;\n    background-color: var(--text-color);\n    color: var(--background-color);\n    transition: background-color 0.5s ease-out, color 0.5s ease-out; }\n", "",{"version":3,"sources":["webpack://src/styles/variables.scss","webpack://src/pages/tags/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,2BAAmB;EACnB,qBAAa;EACb,kCAAe;EACf,kCAAe,EAAA;;ACDjB;EACE,kBAAkB,EAAA;EADpB;IAII,kBAAkB;IAClB,WAAW;IACX,UAAU;IACV,YAAY;IACZ,mCAAmC;IACnC,8BAA8B;IAC9B,+DACmC,EAAA","sourcesContent":[":root {\n  --background-color: #ffffff;\n  --text-color: #000000;\n  --border-color: rgba(0, 0, 0, 0.1);\n  --shadow-color: rgba(0, 0, 0, 0.2);\n}\n\n$theme-duration: 0.5s;\n$mobile-padding: 1.25rem;\n","@import '../../styles/layout.scss';\n@import '../../styles/variables.scss';\n\n.container {\n  position: relative;\n\n  .number {\n    position: absolute;\n    bottom: 6px;\n    right: 6px;\n    padding: 4px;\n    background-color: var(--text-color);\n    color: var(--background-color);\n    transition: background-color #{$theme-duration} ease-out,\n      color #{$theme-duration} ease-out;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__2ADv-",
	"number": "styles_number__3LWL0"
};
export default ___CSS_LOADER_EXPORT___;
