// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root {\n  --background-color: #ffffff;\n  --text-color: #000000;\n  --border-color: rgba(0, 0, 0, 0.1);\n  --shadow-color: rgba(0, 0, 0, 0.2); }\n\n.styles_container__1LAgr {\n  display: flex;\n  flex-direction: column;\n  color: var(--text-color); }\n  .styles_container__1LAgr .styles_swap__1FCaL {\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 10px; }\n    .styles_container__1LAgr .styles_swap__1FCaL .styles_issuer__1JbeZ {\n      display: flex;\n      align-items: center; }\n    .styles_container__1LAgr .styles_swap__1FCaL .styles_buttons__15n2t {\n      display: flex;\n      align-items: center;\n      justify-content: flex-end; }\n      .styles_container__1LAgr .styles_swap__1FCaL .styles_buttons__15n2t > * {\n        margin-left: 10px; }\n", "",{"version":3,"sources":["webpack://src/styles/variables.scss","webpack://src/components/owner-swaps/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,2BAAmB;EACnB,qBAAa;EACb,kCAAe;EACf,kCAAe,EAAA;;ACFjB;EACE,aAAa;EACb,sBAAsB;EACtB,wBAAwB,EAAA;EAH1B;IAMI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB,EAAA;IARvB;MAWM,aAAa;MACb,mBAAmB,EAAA;IAZzB;MAgBM,aAAa;MACb,mBAAmB;MACnB,yBAAyB,EAAA;MAlB/B;QAqBQ,iBAAiB,EAAA","sourcesContent":[":root {\n  --background-color: #ffffff;\n  --text-color: #000000;\n  --border-color: rgba(0, 0, 0, 0.1);\n  --shadow-color: rgba(0, 0, 0, 0.2);\n}\n\n$theme-duration: 0.5s;\n$mobile-padding: 1.25rem;\n","@import '../../styles/variables.scss';\n\n.container {\n  display: flex;\n  flex-direction: column;\n  color: var(--text-color);\n\n  .swap {\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 10px;\n\n    .issuer {\n      display: flex;\n      align-items: center;\n    }\n\n    .buttons {\n      display: flex;\n      align-items: center;\n      justify-content: flex-end;\n\n      & > * {\n        margin-left: 10px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__1LAgr",
	"swap": "styles_swap__1FCaL",
	"issuer": "styles_issuer__1JbeZ",
	"buttons": "styles_buttons__15n2t"
};
export default ___CSS_LOADER_EXPORT___;
