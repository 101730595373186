// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__2ex09 {\n  position: relative;\n  display: block;\n  width: 100%;\n  margin: 0 auto;\n  display: flex;\n  align-items: center;\n  justify-content: center; }\n  .styles_container__2ex09:after {\n    content: '';\n    display: block;\n    padding-bottom: 100%; }\n  .styles_container__2ex09 model-viewer {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translateX(-50%) translateY(-50%); }\n  .styles_container__2ex09 .styles_arButton__lU3m5 {\n    position: absolute;\n    bottom: 10px;\n    left: 10px;\n    color: var(--text-color);\n    background-color: transparent;\n    border-radius: 4px;\n    border: 2px solid var(--text-color);\n    padding: 4px 8px;\n    cursor: pointer; }\n", "",{"version":3,"sources":["webpack://src/components/media-types/glb/styles.module.scss"],"names":[],"mappings":"AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,WAAW;EACX,cAAc;EACd,aAAa;EACb,mBAAmB;EACnB,uBAAuB,EAAA;EAPzB;IAUI,WAAW;IACX,cAAc;IACd,oBAAoB,EAAA;EAZxB;IAgBI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,4CAA4C,EAAA;EAnBhD;IAuBI,kBAAkB;IAClB,YAAY;IACZ,UAAU;IACV,wBAAwB;IACxB,6BAA6B;IAC7B,kBAAkB;IAClB,mCAAmC;IACnC,gBAAgB;IAChB,eAAe,EAAA","sourcesContent":["@import '../../../styles/layout.scss';\n\n.container {\n  position: relative;\n  display: block;\n  width: 100%;\n  margin: 0 auto;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  &:after {\n    content: '';\n    display: block;\n    padding-bottom: 100%;\n  }\n\n  model-viewer {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translateX(-50%) translateY(-50%);\n  }\n\n  .arButton {\n    position: absolute;\n    bottom: 10px;\n    left: 10px;\n    color: var(--text-color);\n    background-color: transparent;\n    border-radius: 4px;\n    border: 2px solid var(--text-color);\n    padding: 4px 8px;\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__2ex09",
	"arButton": "styles_arButton__lU3m5"
};
export default ___CSS_LOADER_EXPORT___;
