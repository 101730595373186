// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root {\n  --background-color: #ffffff;\n  --text-color: #000000;\n  --border-color: rgba(0, 0, 0, 0.1);\n  --shadow-color: rgba(0, 0, 0, 0.2); }\n\n.styles_container__3A2-N {\n  position: relative;\n  width: 100%;\n  min-height: 100vh;\n  padding: 100px 0 0 0; }\n  .styles_container__3A2-N.styles_large__1NK1c {\n    padding: 150px 0 0 0; }\n", "",{"version":3,"sources":["webpack://src/styles/variables.scss","webpack://src/components/layout/page/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,2BAAmB;EACnB,qBAAa;EACb,kCAAe;EACf,kCAAe,EAAA;;ACDjB;EACE,kBAAkB;EAClB,WAAW;EACX,iBAAiB;EACjB,oBAAoB,EAAA;EAJtB;IAOI,oBAAoB,EAAA","sourcesContent":[":root {\n  --background-color: #ffffff;\n  --text-color: #000000;\n  --border-color: rgba(0, 0, 0, 0.1);\n  --shadow-color: rgba(0, 0, 0, 0.2);\n}\n\n$theme-duration: 0.5s;\n$mobile-padding: 1.25rem;\n","@import '../../../styles/variables.scss';\n@import '../../../styles/layout.scss';\n\n.container {\n  position: relative;\n  width: 100%;\n  min-height: 100vh;\n  padding: 100px 0 0 0;\n\n  &.large {\n    padding: 150px 0 0 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__3A2-N",
	"large": "styles_large__1NK1c"
};
export default ___CSS_LOADER_EXPORT___;
