// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_grid__1QPCO {\n  display: flex;\n  margin-left: -10px;\n  width: auto; }\n\n.styles_column__m8LQL {\n  padding-left: 10px;\n  background-clip: padding-box; }\n  .styles_column__m8LQL > * {\n    display: block;\n    margin-bottom: 10px; }\n", "",{"version":3,"sources":["webpack://src/components/responsive-masonry/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,WAAW,EAAA;;AAGb;EACE,kBAAkB;EAClB,4BAA4B,EAAA;EAF9B;IAKI,cAAc;IACd,mBAAmB,EAAA","sourcesContent":[".grid {\n  display: flex;\n  margin-left: -10px;\n  width: auto;\n}\n\n.column {\n  padding-left: 10px;\n  background-clip: padding-box;\n\n  & > * {\n    display: block;\n    margin-bottom: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid": "styles_grid__1QPCO",
	"column": "styles_column__m8LQL"
};
export default ___CSS_LOADER_EXPORT___;
