// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_buttons__3QtO2 {\n  display: flex;\n  flex-wrap: wrap; }\n", "",{"version":3,"sources":["webpack://src/pages/faq/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe,EAAA","sourcesContent":[".buttons {\n  display: flex;\n  flex-wrap: wrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttons": "styles_buttons__3QtO2"
};
export default ___CSS_LOADER_EXPORT___;
